import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ApiConection from "api_ref/ApiConection";
import { ToastError, ToastSuccess } from "components/MyControl";

export default function ButtonPackageContact({title='ติดต่อฝ่ายขาย',className='',style={}}){

    const api = new ApiConection();
    const navigate = useNavigate();

    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const [input, setInput] = useState({});
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);
    const [showToastSuccess, setShowToastSuccess] = useState(false);
    const [showToastError, setShowToastError] = useState(false);
    const [timer, setTimer] = useState(10);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setLoading(false);
        setInput({});
        setShowToastSuccess(false);
        setShow(true);
    }

    useEffect(() => {
        if(showToastSuccess){
            if (timer === 0) {
                setShow(false);
            } else {
                window.setTimeout(() => {
                    setTimer(timer - 1);
                }, 1000);
            }
        }
    }, [timer])

    const handleInputChange = (event) => {
        const { name, value } = event.target;
    
        setInput(prevData => {
            return { ...prevData, [name]: value }
        });
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        debugger;
        setValidated(false);
        setAlert('');
        setLoading(true);
        
        setShowToastSuccess(false);
        setShowToastError(false);

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            setLoading(false);
            return;
        }

        const request = {
            first_name: input.first_name,
            last_name: input.last_name,
            email: input.email,
            phone_number: input.phone_number,
            message: input.message
        }
        
        const response = await api.callLandingPagePostV2(`api/master/contact-us`, request);
        
        if (response.status === true) {
            setShowToastSuccess(true);
            setTimer(5);
        } else {
            setShowToastError(true);
            setAlert(response.message);
        }
        
        setLoading(false);
    }
    return (
        <>
            <button type='button' className={className} style={style} onClick={handleShow}>
                {title}
            </button>
            <Modal show={show} onHide={() => setShow(false)} fullscreen={true}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body  className="container">
                    {
                        !showToastSuccess &&
                        <>
                        <h1 className="pb-3 text-pink-02">ติดต่อฝ่ายขาย เกี่ยวกับ Package Special</h1>
                        <div className="pb-1">
                            <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)} >
                                <Row>
                                <Col sm={6}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label className='label-title'>ชื่อผู้ติดต่อ<span className='text-danger'>*</span></Form.Label>
                                        <Form.Control name={'first_name'} value={input.first_name} placeholder='ชื่อผู้ติดต่อ' required={true} onChange={handleInputChange} />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label className='label-title'>นามสกุล<span className='text-danger'>*</span></Form.Label>
                                        <Form.Control name={'last_name'} value={input.last_name} placeholder='นามสกุล' required={true} onChange={handleInputChange} />
                                    </Form.Group>
                                </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <Form.Group className='mb-3'>
                                            <Form.Label className='label-title'>อีเมลติดต่อ<span className='text-danger'>*</span></Form.Label>
                                            <Form.Control type='email' name={'email'} value={input.email} placeholder='อีเมลติดต่อ' required={true} onChange={handleInputChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group className='mb-3'>
                                            <Form.Label className='label-title'>หมายเลขโทรศัพท์</Form.Label>
                                            <Form.Control type='tel' name={'phone_number'} value={input.phone_number} placeholder='หมายเลขโทรศัพท์' required={true} onChange={handleInputChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    <Form.Group className='mb-3'>
                                        <Form.Label className='label-title'>ข้อความ</Form.Label>
                                        <Form.Control as="textarea" rows={3} name={'message'} value={input.message} placeholder='ข้อความ' maxLength={1000} required={true} onChange={handleInputChange} />
                                    </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <button type='submit' className={className + ' mt-3'} style={style} disabled={loading}>
                                        {
                                            loading === true &&
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        }
                                            ส่งข้อความ
                                        </button><br/>
                                        <p className="mt-3">ฉันเข้าใจว่าข้อมูลส่วนบุคคลของฉันจะได้รับการประมวลผลตาม <a href={process.env.PUBLIC_URL + "/register/Privacypolicy"} target="_blank" >นโยบายความเป็นส่วนตัว</a> ของบริษัท เมจิกซอฟท์แวร์ (ประเทศไทย) จำกัด</p>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        </>
                    }

                    <ToastError show={showToastError} closeFc={setShowToastError} message={'เกิดข้อผิดพลาด!!'} />
                    {
                        showToastSuccess &&
                        <div className="text-center">
                            <h1 className="text-center text-pink-02 mb-5">ขอบคุณที่ส่งข้อความถึงเรา!</h1>
                            <h6 className={`text-center text-pink-02`}>ทีมงานฝ่ายขายของเราได้รับข้อความของท่านเรียบร้อยแล้ว<br/>เราจะดำเนินการตรวจสอบและติดต่อกลับโดยเร็วที่สุด</h6>
                            <p className="mt-5">หน้าจอจะปิดตัวอัตโนมัติภายใน {timer} วินาที</p>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}