import Layout4Register from "components/layout/Layout4Register";
import Css from 'components/pages/application/login/Login.module.css';
import { Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ApiConection from 'api_ref/ApiConection';
import { useEffect, useState } from "react";
import UtilClass from "api_ref/UtilClass";
import LayoutLandingPageV2 from "components/layout/LayoutLandingPageV2";

export default function RegisterConfirm() {
    const api = new ApiConection();
    const util = new UtilClass();
    const navigate = useNavigate();
    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);
    const handleHomePage = () => {
        navigate('/');
    }
    const handleLoginPage = () => {
        navigate('/Login');
    }

    const handleLogin = (event) => {
        navigate('/login');
    }

    const PostConfirm = async () => {
        setLoading(true);
        const response = api.callLandingPagePostV2(`api/authentication/${id}/confirm`);
        if (response.status === true) {
            //console.log(response);
        }
        setLoading(false);
    }

    useEffect(() => {
        debugger;
        if((searchParams.get('period_id') ?? '') !== '' && (searchParams.get('package_id') ?? '')  !== '' ){
            util.setCookie('period_id',parseInt(searchParams.get('period_id')));
            util.setCookie('package_id',parseInt(searchParams.get('package_id')));
        }
        PostConfirm();
    }, [])

    return (
        <LayoutLandingPageV2 middle showContactUs={false}>
            <div className={Css.Login_outer_layout}>

                <div className={`${Css.Login_inner_layout} ${Css.pad} mt-5`}>
                    <Form noValidate validated={validated} onSubmit={handleLoginPage} >
                        <img src={require('images/DCasS.png')} alt={'DCasS'} height={80} className="mt-3 mb-3" onClick={handleHomePage} />
                        <h5 className="mt-4" >ยืนยันบัญชีสำเร็จ</h5>
                        <div className={`alert alert-info mt-4 ${Css.body2}`}>
                            {
                                loading === true &&
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            }
                            <i className="fa-regular fa-circle-check me-1"></i>
                            ขอบคุณที่ยืนยันบัญชีกับ ISO DCaaS จนสำเร็จเรียบร้อยแล้ว<br />
                            ท่านสามารถล็อคอินเข้าสู่ระบบได้เลย
                        </div>
                        <div className="mt-4">
                            <Button type={'submit'} className={'w-100'} >
                                เข้าสู่ระบบ
                            </Button>
                        </div>
                    </Form>
                </div>
                <div className={`mt-4 ${Css.body2}`}>
                    <span className={`me-4 ${Css.line}`}>คุณยังไม่มีบัญชี DCaaS?</span>
                    <a className={Css.Alink_blue} onClick={handleLogin}>คลิกเข้าสู่ระบบ</a>
                </div>
            </div>
        </LayoutLandingPageV2>
    )
}