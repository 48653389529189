import Css from './Mockup.module.css'

const arr_feature = [
    { title: 'ระบบควบคุมเอกสารคุณภาพ ISO และเอกสารทั่วไป' },
    { title: 'Workflow พร้อมใช้งานได้ทันที สามารถกำหนดผู้ทำหน้าที่ในแต่ละขั้นตอนได้โดยง่าย' },
    { title: 'มีการแจ้งเตือนทุกขั้นตอนโดยอัตโนมัติผ่าน email' },
    { title: 'ตรวจสอบสถานะเอกสารในระบบได้แบบ Real Time' },
    { title: 'การจัดทำเอกสาร DAR ขอแก้ไข (Revise) ขอทบทวน (Review) ขอยกเลิก (Cancel)' },
    { title: 'การควบคุมเอกสารบังคับใช้(Revision),ฉบับเดิม,ยกเลิกและย้ายการจัดเก็บเอกสารโดยอัตโนมัติ' },
    { title: 'การควบคุมบันทึกคุณภาพ จัดการ จัดเก็บ ค้นหา ย้ายการจัดเก็บโดยอัตโนมัติเมื่อบันทึกหมดอายุ' },
    { title: 'การตรวจติดตามคุณภาพภายใน (Internal Audit) มี Workflow และ Form บันทึกผลได้ทันที' },
    { title: 'การแก้ไขและป้องกันข้อบกพร่อง (Action Management) มี Workflow และ Form บันทึกผลได้ทันที' }
]

export default function KeyFeatures() {
    return (
        <>
            <div className={Css.keyfeaturesfont}>
                <img src={require("images/keyfeaturesBG.jpg")} className={`${Css.layout_keyfeatures} text-center`} />
                <div className={`${Css.keyfont}`}>
                    <p className={`${Css.keyfont1} mt-5`}>KEY FEATURES</p>
                    {
                        arr_feature.map((obj, index) => {
                            return (
                                <div className={`${Css.font}`} key={index}>
                                    <i className={`${Css.sizeicon} fa-solid fa-caret-right`}></i>
                                    <pre><label className={`${Css.font}`}>{obj.title}</label></pre>
                                </div>

                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}