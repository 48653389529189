import { Button, Form } from "react-bootstrap";
import Css from './Mockup.module.css'
import { useEffect, useState } from "react";
import ApiConection from 'api_ref/ApiConection';
import { useNavigate } from "react-router-dom";
import UtilClass from "api_ref/UtilClass";
import { Row, Col, Carousel } from "react-bootstrap";
import ButtonPackageContact from "./ButtonPackageContact";

const arr_feature = [
    { image: 'banner_slide1.jpg' },
    { image: 'banner_slide2.jpg' },
    { image: 'banner_slide3.jpg' },
    { image: 'banner_slide4.jpg' },
    { image: 'banner_slide5.jpg' },
    { image: 'banner_slide6.jpg' }
]

const package_bg_color = [
    //{color:'#D84CA3'},
    { color: '#C167B0' },
    { color: '#81489A' },
    { color: '#64AEEE' },
    { color: '#3C76C4' },
    { color: '#1E1E1E' },
]

export default function PackageMockup({ handleLogin = undefined, handleRegister = undefined, handleFreeRegister = undefined }) {
    const nav = useNavigate();
    const api = new ApiConection();
    const util = new UtilClass();

    const [input, setInput] = useState({
        period_id: undefined,
        package_id: undefined
    });

    const [loadingPeriod, setLoadingPeriod] = useState(false);
    const [loading, setLoading] = useState(false);

    const [packagePeriod, setPackagePeriod] = useState([]);
    const [packageMaster, setPackageMaster] = useState([]);
    const [packageFree, setPackageFree] = useState([]);
    const [packageSpecial, setPackageSpecial] = useState([]);

    const [index1, setIndex1] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex1(selectedIndex);
    };

    const handleGetClass = (idx) => {
        if (index1 == idx) return (Css.slideshowDot_active);
        else return Css.slideshowDot;
    }

    const getPackagePeriod = async () => {
        setLoadingPeriod(true);

        const response = await api.callLandingPageGet('api/master/package-period');
        let is_declare = false;

        if (response.status === true) {
            setPackagePeriod(response.data);

            setInput(pervData => {
                return { ...pervData, ['period_id']: response.data[0].id }
            })
            util.setCookie('period_id', response.data[0].id);
        }

        setLoadingPeriod(false);
    }
    const getPackageMaster = async () => {
        if (input.period_id === undefined) return;

        setLoading(true);
        const responseFree = await api.callLandingPageGet(`api/package/type/free`);
        if (responseFree.status === true) {
            setPackageFree(responseFree.data);
        }

        const responseSemi = await api.callLandingPageGet(`api/package/type/semi`);
        if (responseSemi.status === true) {
            setPackageSpecial(responseSemi.data);
        }

        const response = await api.callLandingPageGet(`api/package/type/package/period/${input.period_id}`);
        if (response.status === true) {
            setPackageMaster(response.data);
        }
        setLoading(false);
    }

    const handleChange = (event) => {
        const { name, type, value } = event.target;

        setInput(prevData => {
            return { ...prevData, [name]: value }
        })
    }

    const handlePackageClick = (event, package_id) => {
        event.preventDefault();

        setInput(prevData => {
            return { ...prevData, package_id: package_id }
        });
        let data = input;
        data.package_id = package_id;

        util.setSessionStorage('package_selected', data);

        util.setCookie('period_id', input.period_id);
        util.setCookie('package_id', package_id);

        nav('/register');
    }

    const handleCompare = (event) => {
        nav('/compare');
    }

    const compileDigit = (number) => {
        if (number % 1 === 0) {
            return parseInt(number).toFixed(0);
        } else {
            return parseFloat(number).toFixed(1);
        }
    }

    useEffect(() => {
        getPackagePeriod();
    }, [])
    useEffect(() => {
        getPackageMaster();
    }, [input.period_id])

    return (
        packagePeriod &&
        <div id='div_package_mockup' className={`align-items-start m-auto position-relative text-center ${Css.layout_outside_Package}`}>
            {/***slide***/}
            <div className="col" >
                <div className='text-center'>
                    <div>
                        <label className={`me-2 mb-3 ${Css.fontHeadPackage}`}>ราคาแพ็กเกจ</label>
                        <img alt={''} height={55} src={require("images/DCasS.png")} />
                    </div>

                    <div className={Css.AllPackagePeriod}>
                        {/*Package Period*/}
                        {
                            packagePeriod?.length > 1 &&
                            <div className={Css.Package_Period}>
                                {
                                    packagePeriod.map((obj, index) => (
                                        <Form.Check key={index} type={'radio'} className={`${Css.Form_Check}`} name={'period_id'} id={'rdo_period_' + index} label={obj.name} value={obj.id} defaultChecked={obj.id === input.period_id} onChange={handleChange} />
                                    ))
                                }
                                {
                                    loadingPeriod === true &&
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }
                            </div>
                        }

                        {/*Package*/}
                        <div className={`${Css.AllPackageBox}`}>
                            {/*Package Free*/}
                            {packageFree.map((obj_package, index) => (
                                <div className={Css.Package_Box} key={index}>
                                    <div className={Css.HeadBox}><label className={Css.fontHeadBox}>
                                        {
                                            loading === true &&
                                            <div className="spinner-border spinner-border-sm text-light" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        }
                                        {obj_package.name}

                                    </label></div>
                                    <div className={Css.PackagePrice}><label className={Css.fontPackagePrice}>{obj_package.unit}</label></div>
                                    <div className={Css.Boxline} />
                                    <div className={Css.Feature}>
                                        <li className="mb-2">
                                            <i className="fa-solid fa-caret-right fa-2xl me-2 "></i>
                                            <label className={Css.fontFeature}>ใช้ได้สูงสุดจำนวน {obj_package.number_of_user} ผู้ใช้งาน</label>
                                        </li>
                                        <li >
                                            <i className="fa-solid fa-caret-right fa-2xl me-2"></i>
                                            <label className={Css.fontFeature}>พื้นที่จัดเก็บข้อมูล {compileDigit(obj_package.number_of_storage)} GB</label>
                                        </li>
                                    </div>
                                    <button type="button" className={Css.btnPackage} onClick={event => handlePackageClick(event, obj_package.id)}>ทดลองใช้งานฟรี</button>
                                </div>
                            ))}

                            {/*Package Not Free*/}
                            {packageMaster.map((obj_package, index) => (
                                <div className={Css.Package_Box} key={index}>
                                    {
                                        obj_package.recommend === true &&
                                        <div className={Css.Recommand}>
                                            <span>แพ็กเกจแนะนำ</span>
                                        </div>
                                    }
                                    {
                                        <>
                                            <div className={Css.HeadBox0} style={{ backgroundColor: package_bg_color[index]?.color }}><label className={Css.fontHeadBox}>
                                                {
                                                    loading === true &&
                                                    <div className="spinner-border spinner-border-sm text-light" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                                {obj_package.name}
                                            </label></div>
                                            <div className={Css.PackagePrice}>
                                                <label className={Css.fontPackagePrice1}>{obj_package.total}</label><br />
                                                <label className={Css.fontPackageUnit}>{obj_package.unit}</label>
                                                {/*<label className={Css.fontPackageUnit}>{obj_package.unit}<br />เฉลี่ย {obj_package?.price_per_unit.replace('.00', '')} บาท/ผู้ใช้งาน</label> */}
                                            </div>
                                            <div className={Css.Boxline} />
                                            <div className={Css.Feature}>
                                                <li className="mb-2">
                                                    <i className="fa-solid fa-caret-right fa-2xl me-2 "></i>
                                                    <label className={Css.fontFeature}>ใช้ได้สูงสุดจำนวน {obj_package.number_of_user} ผู้ใช้งาน</label>
                                                </li>
                                                <li >
                                                    <i className="fa-solid fa-caret-right fa-2xl me-2"></i>
                                                    <label className={Css.fontFeature}>พื้นที่จัดเก็บข้อมูล {compileDigit(obj_package.number_of_storage)} GB</label>
                                                </li>
                                            </div>
                                            <button type="button" className={Css.btnPackage0} style={{ background: package_bg_color[index]?.color, borderColor: package_bg_color[index]?.color }} onClick={event => handlePackageClick(event, obj_package.id)}>สมัครใช้งาน</button>
                                        </>
                                    }
                                </div>
                            ))}
                            {/* pacakge speceil */}
                            {packageSpecial.map((obj_package, index) => (
                                <div className={Css.Package_Box} key={index}>
                                    <div className={Css.HeadBox} style={{ backgroundColor: package_bg_color[4]?.color }}><label className={Css.fontHeadBox}>
                                        {
                                            loading === true &&
                                            <div className="spinner-border spinner-border-sm text-light" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        }
                                        {obj_package.name}

                                    </label></div>
                                    <div className={Css.PackagePrice}><label className={Css.fontPackagePrice}>จำนวนมากกว่า<br />100 ผู้ใช้งาน</label></div>

                                    {/*
                                      <div className={Css.Feature}>
                                        <li className="mb-2">
                                            <i className="fa-solid fa-caret-right fa-2xl me-2 "></i>
                                            <label className={Css.fontFeature}>จำนวนผู้ใช้งานมากว่า 100 ผู้ใช้งาน</label>
                                        </li>
                                        <li >
                                            <i className="fa-solid fa-caret-right fa-2xl me-2"></i>
                                            <label className={Css.fontFeature}>พื้นที่จัดเก็บข้อมูลมากกว่า 10.00 GB</label>
                                        </li>
                                    </div>
                                    */
                                    }
                                    <ButtonPackageContact title={'ติดต่อฝ่ายขาย'} className={Css.btnPackage} style={{ background: package_bg_color[4]?.color, borderColor: package_bg_color[4]?.color, marginTop: "113px" }} />
                                    {/*<Button type="button" onClick={event => handlePackageClick(event, obj_package.id)} className={Css.btnPackage} style={{background:package_bg_color[4]?.color,borderColor:package_bg_color[4]?.color}}>ทดลองใช้งานฟรี</Button>*/}
                                </div>
                            ))}
                        </div>
                    </div>

                    {/*Package Moblie*/}
                    <div className={Css.AllSlide_Moblie_Package}>
                        <Carousel activeIndex={index1} onSelect={handleSelect}>
                            <Carousel.Item>
                                {packageFree.map((obj_package, index) => (

                                    <div className={Css.Package_Box} key={index}>
                                        <div className={Css.HeadBox}><label className={Css.fontHeadBox}>
                                            {
                                                loading === true &&
                                                <div className="spinner-border spinner-border-sm text-light" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            }
                                            {obj_package.name}

                                        </label></div>
                                        <div className={Css.PackagePrice}><label className={Css.fontPackagePrice}>{obj_package.unit}</label></div>
                                        <div className={Css.Boxline} />
                                        <div className={Css.Feature}>
                                            <li className="mb-2">
                                                <i className="fa-solid fa-caret-right fa-2xl me-2 "></i>
                                                <label className={Css.fontFeature}>ใช้ได้สูงสุดจำนวน {obj_package.number_of_user} ผู้ใช้งาน</label>
                                            </li>
                                            <li >
                                                <i className="fa-solid fa-caret-right fa-2xl me-2"></i>
                                                <label className={Css.fontFeature}>พื้นที่จัดเก็บข้อมูล {compileDigit(obj_package.number_of_storage)} GB</label>
                                            </li>
                                        </div>
                                        <button type="button" className={Css.btnPackage} onClick={event => handlePackageClick(event, obj_package.id)}>ทดลองใช้งานฟรี</button>
                                    </div>
                                ))}
                            </Carousel.Item>
                            {packageMaster.map((obj_package, index) => (
                                <Carousel.Item key={index}>
                                    <div className={Css.Package_Box} key={index}>
                                        {
                                            obj_package.recommend === true &&
                                            <div className={Css.Recommand}>
                                                <span>แพ็กเกจแนะนำ</span>
                                            </div>
                                        }
                                        {
                                            <>
                                                <div className={Css.HeadBox0} style={{ backgroundColor: package_bg_color[index]?.color }}><label className={Css.fontHeadBox}>
                                                    {
                                                        loading === true &&
                                                        <div className="spinner-border spinner-border-sm text-light" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    }
                                                    {obj_package.name}
                                                </label></div>
                                                <div className={Css.PackagePrice}>
                                                    <label className={Css.fontPackagePrice1}>{obj_package.total}</label><br />
                                                    <label className={Css.fontPackageUnit}>{obj_package.unit}</label>
                                                    {/*<label className={Css.fontPackageUnit}>{obj_package.unit}<br />เฉลี่ย {obj_package?.price_per_unit.replace('.00', '')} บาท/ผู้ใช้งาน</label> */}
                                                </div>
                                                <div className={Css.Boxline} />
                                                <div className={Css.Feature}>
                                                    <li className="mb-2">
                                                        <i className="fa-solid fa-caret-right fa-2xl me-2 "></i>
                                                        <label className={Css.fontFeature}>ใช้ได้สูงสุดจำนวน {obj_package.number_of_user} ผู้ใช้งาน</label>
                                                    </li>
                                                    <li >
                                                        <i className="fa-solid fa-caret-right fa-2xl me-2"></i>
                                                        <label className={Css.fontFeature}>พื้นที่จัดเก็บข้อมูล {compileDigit(obj_package.number_of_storage)} GB</label>
                                                    </li>
                                                </div>
                                                <button type="button" className={Css.btnPackage0} style={{ background: package_bg_color[index]?.color, borderColor: package_bg_color[index]?.color }} onClick={event => handlePackageClick(event, obj_package.id)}>สมัครใช้งาน</button>
                                            </>
                                        }
                                    </div>
                                </Carousel.Item>
                            ))}
                            <Carousel.Item>
                                {packageSpecial.map((obj_package, index) => (
                                    <div className={Css.Package_Box} key={index}>
                                        <div className={Css.HeadBox} style={{ backgroundColor: package_bg_color[4]?.color }}><label className={Css.fontHeadBox}>
                                            {
                                                loading === true &&
                                                <div className="spinner-border spinner-border-sm text-light" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            }
                                            {obj_package.name}

                                        </label></div>
                                        <div className={Css.PackagePrice}><label className={Css.fontPackagePrice}>จำนวนมากกว่า<br />100 ผู้ใช้งาน</label></div>

                                        {/*
                                      <div className={Css.Feature}>
                                        <li className="mb-2">
                                            <i className="fa-solid fa-caret-right fa-2xl me-2 "></i>
                                            <label className={Css.fontFeature}>จำนวนผู้ใช้งานมากว่า 100 ผู้ใช้งาน</label>
                                        </li>
                                        <li >
                                            <i className="fa-solid fa-caret-right fa-2xl me-2"></i>
                                            <label className={Css.fontFeature}>พื้นที่จัดเก็บข้อมูลมากกว่า 10.00 GB</label>
                                        </li>
                                    </div>
                                    */
                                        }
                                        <ButtonPackageContact title={'ติดต่อฝ่ายขาย'} className={Css.btnPackage} style={{ background: package_bg_color[4]?.color, borderColor: package_bg_color[4]?.color, marginTop: "113px" }} />
                                        {/*<Button type="button" onClick={event => handlePackageClick(event, obj_package.id)} className={Css.btnPackage} style={{background:package_bg_color[4]?.color,borderColor:package_bg_color[4]?.color}}>ทดลองใช้งานฟรี</Button>*/}
                                    </div>
                                ))}
                            </Carousel.Item>
                        </Carousel>

                        <div className={Css.slideshowDots}>
                            {arr_feature.map((_, idx) => (
                                <div
                                    key={idx}
                                    className={handleGetClass(idx)}
                                    onClick={() => { setIndex1(idx); }}
                                ></div>
                            ))}
                        </div>
                    </div>

                    {/*<div className={`${Css.comparetxt}`}> <a className={Css.compareMockup} onClick={handleCompare}>ตารางเปรียบเทียบแพ็กเกจ <i className="fa-solid fa-chevron-right fa-xs" style={{ color: "#1E1E1E", }} /> </a> </div> */}
                </div>
            </div>
        </div>
    )
}